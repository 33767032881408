<template>
    <div>
        <v-container v-if="agencyTrip.id">
            <v-expand-transition>
                <v-alert
                v-show="alert"
                border="left"
                text
                :type="alertType"
                >
                    {{alertText}}
                </v-alert>
            </v-expand-transition>
            <v-row>
                <v-col cols="12" md="5">
                    <v-img height="100%" class="rounded-xl" :src="agencyTrip.image"></v-img>
                </v-col>
                <v-col cols="12" md="7">
                    <div>
                        <div class="d-flex justify-space-between align-center mb-3">
                            <h1>{{agencyTrip.name}}</h1>
                            <div v-if="$cookies.isKey('gt_user_token')" class="text-right">
                                <v-btn icon color="primary"><v-icon>mdi-phone</v-icon></v-btn>
                                <v-btn icon color="primary"><v-icon>mdi-heart-outline</v-icon></v-btn>
                                <v-btn icon color="primary" @click="socialDialog = true"><v-icon>mdi-share-variant</v-icon></v-btn>
                                <v-btn v-if="$route.name !== 'tripBooking' && $route.name !== 'memory'" @click="$router.push({name: 'tripBook', params: {id: agencyTrip.id}})" class="d-block mt-3" block color="primary" elevation="0">Book now</v-btn>
                                <v-btn v-else @click="$router.push({name: 'chat', params: {id: 1}})" class="d-block mt-3" block color="primary" elevation="0"><v-icon>mdi-chat</v-icon> chat with the agency</v-btn>
                            </div>
                            <div v-else>
                                <v-btn @click="$router.push({name: 'login'})" class="d-block mt-3" block color="primary" elevation="0"><v-icon left>mdi-login</v-icon> Login to continue</v-btn>
                            </div>
                        </div>
                        <h5 class="paragraph--text pr-5" v-if="agencyTrip.city"><v-icon>mdi-map-marker</v-icon>{{agencyTrip.city}}</h5>
                        <v-rating
                        color="warning"
                        dense
                        full-icon="mdi-star"
                        length="3"
                        readonly
                        size="20"
                        :value="3"
                        ></v-rating>

                        <v-dialog
                        v-model="dialog"
                        width="500"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                text
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="d-block"
                                >
                                <v-icon>mdi-map</v-icon>
                                show on map
                                </v-btn>
                            </template>
                            <v-card class="rounded-xl pa-3 mt-5">
                                <v-card-title class="body-1 font-weight-bold">Trip Location</v-card-title>
                                <h4 class="paragraph--text" v-if="agencyTrip.city"><v-icon>mdi-map-marker</v-icon>{{agencyTrip.city}}</h4>
                                <GmapMap
                                :center='{lat: agencyTrip.lat || 0, lng: agencyTrip.lng || 0}'
                                ref="gmap"
                                :zoom='8'
                                style='width:100%;  height: 200px;'
                                >
                                <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in [{position: {lat: agencyTrip.lat || 0, lng: agencyTrip.lng || 0}}]"
                                    :position="m.position"
                                />
                                </GmapMap>
                            </v-card>
                        </v-dialog>

                        <v-chip color="#F5F5F5" v-for="(item, i) in agencyTrip.tags" :key="i" class="ma-1">
                            {{item.name}}
                            <br>
                            <v-rating
                            background-color="primary lighten-1"
                            color="primary"
                            empty-icon="mdi-star-outline"
                            full-icon="mdi-star"
                            length="5"
                            readonly
                            size="15"
                            dense
                            :value="item.pivot.rank ? item.pivot.rank : 1"
                            ></v-rating>
                        </v-chip>

                        <v-row v-if="agencyTrip.images.length" no-gutters>
                            <template v-for="(item, i) in agencyTrip.images">
                                <v-col v-if="i < 4" class="mt-2 pa-1" :key="i" cols="12" sm="6" md="3">
                                    <v-img :gradient="i === 3 ? 'to top right, rgb(239 25 183 / 70%), rgba(239 25 183 / 70%)' : ''"
                                    class="d-flex align-center justify-center pa-0 rounded-xl"
                                    :src="item.image_name"
                                    height="150"
                                    >
                                        <v-btn @click="imagesDilaog = true" v-if="i === 3" text color="white" class="font-weight-bold" x-large>+ {{agencyTrip.images.length - 3}}</v-btn>
                                    </v-img>
                                </v-col>
                            </template>
                        </v-row>
                </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <v-card class="pa-5 rounded-xl">
                        <v-img width="100" class="mx-auto mb-3" :src="agencyTrip.agency.user.profile_photo"></v-img>
                        <v-row class="text-capitalize">
                            <v-col cols="12" sm="6" md="4">
                                <h5><v-icon class="mr-2">mdi-account</v-icon>agency name:</h5>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                                <p class="body-1 mx-5 grey--text">{{agencyTrip.agency.name}}</p>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <h5><v-icon class="mr-2">mdi-web</v-icon>website:</h5>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                                <p class="body-1 mx-5 grey--text">{{agencyTrip.agency.website}}</p>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <h5><v-icon class="mr-2">mdi-phone</v-icon>contact</h5>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                                <p class="body-1 mx-5 grey--text">{{agencyTrip.agency.phone}}</p>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <h5><v-icon class="mr-2">mdi-mail</v-icon>Email:</h5>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                                <p class="body-1 mx-5 grey--text">{{agencyTrip.agency.user.email}}</p>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <h5><v-icon class="mr-2">mdi-map</v-icon>location:</h5>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                                <p class="body-1 mx-5 grey--text">{{agencyTrip.agency.address}}</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="7">
                    <div v-if="$route.name !== 'tripBooking' && $route.name !== 'memory'">
                        <v-card outlined class="px-5 rounded-xl mb-5">
                            <v-card-title class="primary--text text-h5 text-capitalize">recommended date</v-card-title>
                            <v-card-text class="text-h6"><v-icon>mdi-calendar</v-icon> {{agencyTrip.date}}</v-card-text>
                        </v-card>
                        <v-card outlined class="px-5 rounded-xl my-5">
                            <v-card-text class="d-flex justify-space-between flex-wrap">
                                <div>
                                    <h2 class="primary--text mb-3">No. of days</h2>
                                    <h2 class="text-center">{{agencyTrip.days}}</h2>
                                </div>
                                <div>
                                    <h2 class="primary--text mb-3">No. of nights</h2>
                                    <h2 class="text-center">{{agencyTrip.nights}}</h2>
                                </div>
                                <div>
                                    <h2 class="primary--text mb-3">trip occupancy</h2>
                                    <h2 class="text-center">{{agencyTrip.occupancy}}</h2>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-card outlined class="px-5 rounded-xl my-5">
                            <v-card-text class="d-flex justify-space-between flex-wrap">
                                <div>
                                    <h2 class="primary--text mb-3">price/person</h2>
                                    <h2 class="text-center">{{agencyTrip.price_per_person | currency}}</h2>
                                </div>
                                <div>
                                    <h2 class="primary--text mb-3">solo price</h2>
                                    <h2 class="text-center">{{agencyTrip.solo_price | currency}}</h2>
                                </div>
                                <div>
                                    <h2 class="primary--text mb-3">lemo price</h2>
                                    <h2 class="text-center">{{agencyTrip.limo_price | currency}}</h2>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card v-if="tripTravellers.length" outlined class="px-5 rounded-xl my-5">
                            <v-card-title class="primary--text text-h5 text-capitalize">Confirmed travellers</v-card-title>
                            <v-row class="ma-5 mt-0">
                                <template v-for="item in tripTravellers">
                                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-if="item.full_name" class="text-center" :key="item.id">
                                        <div style="cursor: pointer;" @click="$router.push({name: 'friendProfile', params: {id: item.id}})">

                                            <v-avatar v-if="item.profile_photo" size="62">
                                                <img
                                                :src="item.profile_photo"
                                                :alt="item.full_name"
                                                class="mb-3 rounded-circle"
                                                width="62"
                                                height="62"
                                                >
                                            </v-avatar>
                                            <v-avatar v-else-if="item.avatar" size="62">
                                                <img
                                                :src="item.avatar"
                                                :alt="item.full_name"
                                                class="mb-3 rounded-circle"
                                                width="62"
                                                height="62"
                                                >
                                            </v-avatar>
                                            <v-avatar
                                            v-else
                                            color="primary"
                                            size="62"
                                            >
                                                <span v-if="item.full_name" class="white--text text-capitalize">{{item.full_name.charAt(0)}}</span>
                                                <!-- <span v-else class="white--text text-capitalize">{{item.user_name.charAt(0)}}</span> -->
                                            </v-avatar>
                                            <h3 class="paragraph--text bold-text text-capitalize my-1">{{item.full_name.split(' ')[0]}} {{item.full_name.split(' ')[1]}}</h3>
                                            <!-- <p class="paragraph--text text-capitalize mb-1" v-text="item.nick_name"></p> -->
                                            <v-progress-linear
                                            background-color="primary"
                                            color="primaryDark"
                                            :value="item.matching_percentage"
                                            class="mb-1 mx-auto"
                                            height="12"
                                            style="max-width: 100px; height: 20px;"
                                            rounded
                                            >
                                                <template v-slot:default>
                                                <small class="white--text font-weight-bold body-2 mt-1">{{ item.matching_percentage }}%</small>
                                                </template>
                                            </v-progress-linear>
                                        </div>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-card outlined>
                            <v-card-title class="primary--text text-h5 text-capitalize">Booking Information</v-card-title>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-if="$route.name === 'tripBooking'" class="text-left">Booking Reference</th>
                                        <th class="text-left">Adults</th>
                                        <th class="text-left">Children</th>
                                        <th class="text-left">Infants</th>
                                        <th class="text-left">Price</th>
                                        <th class="text-left">Status</th>
                                        <th class="text-left">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-if="$route.name === 'tripBooking'">{{ tripBooking.booking_reference }}</td>
                                        <td>{{ tripBooking.number_of_adults }}</td>
                                        <td>{{ tripBooking.number_of_children }}</td>
                                        <td>{{ tripBooking.number_of_infants }}</td>
                                        <td>{{ tripBooking.paid_price | currency }}</td>
                                        <td>{{ tripBooking.status }}</td>
                                        <td>{{ new Date(tripBooking.created_at).toDateString() }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <v-card v-if="tripBooking.passenger_details" outlined class="my-5">
                            <v-card-title class="primary--text text-h5 text-capitalize">Travellers Information</v-card-title>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">BirthDate</th>
                                        <th class="text-left">Passport</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in tripBooking.passenger_details" :key="item.id">
                                        <td>{{ item.full_name }}</td>
                                        <td>{{ new Date(item.birth_of_date).toDateString() }}</td>
                                        <td>{{ item.passport_number }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <v-card v-if="tripTravellers.length" outlined class="px-5 rounded-xl my-5">
                            <v-card-title class="primary--text text-h5 text-capitalize">Confirmed travellers</v-card-title>
                            <v-row class="ma-5 mt-0">
                                <template v-for="item in tripTravellers">
                                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-if="item.full_name" class="text-center" :key="item.id">
                                        <div style="cursor: pointer;" @click="$router.push({name: 'friendProfile', params: {id: item.id}})">
                                            <v-avatar v-if="item.profile_photo" size="62">
                                                <img
                                                :src="item.profile_photo"
                                                :alt="item.full_name"
                                                class="mb-3 rounded-circle"
                                                width="62"
                                                height="62"
                                                >
                                            </v-avatar>
                                            <v-avatar v-else-if="item.avatar" size="62">
                                                <img
                                                :src="item.avatar"
                                                :alt="item.full_name"
                                                class="mb-3 rounded-circle"
                                                width="62"
                                                height="62"
                                                >
                                            </v-avatar>
                                            <v-avatar
                                            v-else
                                            color="primary"
                                            size="62"
                                            >
                                                <span v-if="item.full_name" class="white--text text-capitalize">{{item.full_name.charAt(0)}}</span>
                                                <!-- <span v-else class="white--text text-capitalize">{{item.user_name.charAt(0)}}</span> -->
                                            </v-avatar>
                                            <h3 class="paragraph--text bold-text text-capitalize my-1">{{item.full_name.split(' ')[0]}} {{item.full_name.split(' ')[1]}}</h3>
                                            <!-- <p class="paragraph--text text-capitalize mb-1" v-text="item.nick_name"></p> -->
                                            <v-progress-linear
                                            background-color="primary"
                                            color="primaryDark"
                                            :value="item.matching_percentage"
                                            class="mb-1 ml-10"
                                            height="12"
                                            style="max-width: 100px; height: 20px;"
                                            rounded
                                            >
                                                <template v-slot:default>
                                                <small class="white--text font-weight-bold body-2 mt-1">{{ item.matching_percentage }}%</small>
                                                </template>
                                            </v-progress-linear>
                                        </div>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-card>

                        <div>
                            <v-row no-gutters>
                                <template v-for="(item, i) in tripBooking.memory_images">
                                    <v-col v-if="i < 4" class="mt-2 pa-1" :key="i" cols="12" sm="6" md="3">
                                        <v-img
                                        :gradient="i === 3 ? 'to top right, rgb(239 25 183 / 70%), rgba(239 25 183 / 70%)' : ''"
                                        class="d-flex align-center justify-center pa-0 rounded-xl"
                                        :src="item"
                                        height="150"
                                        >
                                            <v-btn @click="memoryImagesDilaog = true" v-if="i === 3" text color="white" class="font-weight-bold" x-large>+ {{tripBooking.memory_images.length - 3}} photos</v-btn>
                                        </v-img>
                                    </v-col>
                                </template>
                            </v-row>
                            <div class="d-flex align-center py-7 justify-end rounded-xl upload-div">
                                <v-btn :loading="imgLoading" text color="primary" @click="activateImgUpload">
                                    <v-icon color="primary">mdi-tray-arrow-up</v-icon>
                                    <span>Upload Images</span>
                                </v-btn>
                            </div>
                            <input
                            ref="multipleUploader"
                            class="d-none"
                            type="file"
                            accept="image/*"
                            @change="onFileChanged"
                            multiple
                            >
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-dialog max-width="80%" v-model="imagesDilaog">
                <v-card class="pa-5">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="item in agencyTrip.images" :key="item.id">
                            <v-img
                            class="rounded-xl"
                            :src="item.image_name">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>

            <v-dialog v-if="$route.name === 'memory'" max-width="80%" v-model="memoryImagesDilaog">
                <v-card class="pa-5">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="item in tripBooking.memory_images" :key="item.id">
                            <v-img
                            class="rounded-xl"
                            :src="item">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>

            <v-dialog v-model="socialDialog">
            <v-card>
                <v-card-title>Share your trip link</v-card-title>
                <v-card-text class="pa-10">
                <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :style="{backgroundColor: network.color, margin: '50px', padding: '10px', color: 'white'}"
                :url="currentUrl + '/trips/announce/' + agencyTrip.id"
                :title="agencyTrip.name"
                :description="agencyTrip.city + ' \n ' + agencyTrip.agency.name"
                :quote="getQuote()"
                >
                    <v-icon class="mb-2" color="white" left>{{network.icon}}</v-icon>
                    <span>{{ network.name }}</span>
                </ShareNetwork>
            </v-card-text>
            </v-card>
            </v-dialog>
        </v-container>
        <p v-else class="text-center primary--text mt-10"><v-icon color="warning" left>mdi-alert</v-icon>Couldn't fetch data</p>
    </div>
</template>

<script>
import user from '../../web_services/user'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      imagesDilaog: false,
      memoryImagesDilaog: false,
      dialog: false,
      images: [],
      imgLoading: false,
      alert: '',
      alertType: 'success',
      alertText: '',
      socialDialog: false,
      currentUrl: '',
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: '#1877f2' },
        { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' }
      ],
      tripTravellers: []
    }
  },
  computed: {
    ...mapState(['agencyTrip', 'tripBooking']),
    previewImages () {
      const imagesURLs = []
      this.images.forEach(item => {
        imagesURLs.push(URL.createObjectURL(item))
      })
      return imagesURLs
    }
  },
  methods: {
    activateImgUpload () {
      window.addEventListener('focus', () => {
      }, { once: true })
      this.$refs.multipleUploader.click()
    },
    onFileChanged (e) {
      this.images = []
      const images = Array.from(e.target.files)
      images.forEach(item => this.images.push(item))
      this.uploadImages()
    },
    uploadImages () {
      this.imgLoading = true
      const formData = new FormData()
      this.images.forEach((item, i) => {
        formData.append(`images[${i}]`, item, item.name)
      })
      user.addMemoryImages(formData, this.$route.params.id).then(res => {
        this.$store.dispatch('setTripBooking', res.data.data)
        this.$store.dispatch('setAgencyTrip', res.data.data.trip)
        this.showAlert('success', 'Added Successfully')
      }).catch(error => {
        let text = ''
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          value.forEach(item => {
            text += item + ' ! '
          })
        })
        this.showAlert('error', text)
      }).finally(() => {
        this.imgLoading = false
      })
    },
    getQuote () {
      const article = this.agencyTrip.name + '\n' + this.agencyTrip.city
      return article
    },
    showAlert (type, message) {
      this.alertText = message
      this.alertType = type
      this.alert = true
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    getTripTravellers () {
      console.log(this.tripBooking)
      let id = this.agencyTrip.id
      if (this.$route.name === 'tripBooking' || this.$route.name === 'memory') {
        console.log('in')
        id = this.tripBooking.trip.id
      }
      user.tripTravellers(id).then(res => {
        console.log('trav', res.data.data.data)
        this.tripTravellers = res.data.data.data
      }).catch((err) => {
        console.log('err', err)
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.currentUrl = window.location.origin
    if (!this.agencyTrip.id) {
      user.trip(this.$route.params.id).then(res => {
        this.$store.dispatch('setAgencyTrip', res.data.data)
        this.getTripTravellers()
      }).catch(() => {
        console.log('err', this.$route.params.id)
      })
    } else {
      this.getTripTravellers()
    }
  }
}
</script>
